<template>
<div>
    <b-card v-if="users" no-body class="card-company-table">
        <b-table :items="users" responsive :fields="fields" class="mb-0">
            <!-- Id -->
            <template #cell(id)="data">
                <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
            </template>

            <template #cell(status)="data">
                <b-badge pill :variant="getExamStatus(data.item.status)">
                    {{ getExamStatusText(data.item.status) }}
                </b-badge>
            </template>

            <!-- Id -->
            <template #cell(username)="data">
                <span class="font-weight-bolder mb-12">{{
                    (data.item.user!=null)?
                    data.item.user.firstName+' '+data.item.user.lastName:
                    "Kullanıcı Bulunamadı"
                    }}</span>
            </template>

        </b-table>
    </b-card>
</div>
</template>

<script>
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BProgress
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    components: {
        ToastificationContent,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        vSelect,
        BProgress
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'username', label: 'Öğrenci Adı Soyadı' },
                { key: 'title', label: 'Test' },
                { key: 'startDate', label: 'Başlama Tarihi' },
                { key: 'endDate', label: 'Bitiş Tarihi' },
                { key: 'status', label: 'Durum' },
                { key: 'correct', label: 'Doğru' },
                { key: 'wrong', label: 'Yanlış' },
                { key: 'empty', label: 'Boş' },
                { key: 'point', label: 'Puan' },
            ],
            users: [],
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var users = await this.$http.get("Report/DailyTest");
            this.users = users.data.data;
            //console.log(this.users);
        },
        getExamStatus(status) {
            switch (status) {
                case 'Open':
                    return 'primary'
                case 'Waiting':
                    return 'info'
                case 'Closed':
                    return 'secondary'
                case 'Processing':
                    return 'warning'
                case 'HasResulted':
                    return 'success'
            }
        },
        getExamStatusText(status) {
            switch (status) {
                case 'Open':
                    return "Başlamadı";
                case 'Waiting':
                    return "Devam Ediyor";
                case 'Closed':
                    return "Kapalı";
                case 'Processing':
                    return "Sonuçlar İşleniyor";
                case 'HasResulted':
                    return "Sonuçlandı";
            }
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
